import { Collapse, Form, Input, Select, Upload } from "antd";
import { AssistantType } from "./types";
import { getValueFromEvent } from "@refinedev/antd";
import { FormProps } from "antd/lib";
import { MarkdownEditor } from "components/markdown-editor";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const AssistantForm = ({
    data,
    formProps,
}: {
    data?: AssistantType;
    formProps: FormProps;
}) => {
    const { t } = useTranslation();

    const LANGUAGE_MODEL_CHOICES = [
        {
            value: "gpt-3.5-turbo",
            label: t("assistants.fields.models.gpt3.5", "GPT-3.5 Turbo"),
        },
        {
            value: "gpt-4",
            label: t("assistants.fields.models.gpt4", "GPT-4 Turbo"),
        },
        {
            value: "gpt-4o",
            label: t("assistants.fields.models.gpt4o", "GPT-4o"),
        },
        {
            value: "gpt-4o-mini",
            label: t("assistants.fields.models.gpt4o-mini", "GPT-4o-mini"),
        },
    ];

    const [iconToDelete, setIconToDelete] = useState(false);

    if (!formProps) {
        return null;
    }

    return (
        <Form
            {...formProps}
            layout="vertical"
            initialValues={data}
            onFinish={async (values) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const { icons, ...otherValues } = values as any;
                const icon =
                    (icons && icons[0] && icons[0].originFileObj) || null;
                if (icon) {
                    otherValues.icon = icon;
                }

                if (iconToDelete) {
                    otherValues.icon = "";
                }

                return formProps.onFinish?.({
                    ...otherValues,
                });
            }}
        >
            <Form.Item
                label={t("assistants.fields.name", "Name")}
                name={["name"]}
                rules={[{ required: true }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t(
                    "assistants.fields.instructions",
                    "Instructions"
                )}
                name={["instructions"]}
                rules={[{ required: true }]}
            >
                <MarkdownEditor preview="edit" />
            </Form.Item>
            <Form.Item
                label={t("assistants.fields.languageModel", "Language Model")}
                name={["language_model"]}
                required
            >
                <Select options={LANGUAGE_MODEL_CHOICES} />
            </Form.Item>
            <Collapse
            className="mb-4"
            >
                <Collapse.Panel header={t("assistants.fields.lookAndFeel", "Look and Feel")} key="1">
                <Form.Item
                label={t("assistants.fields.initialMessage", "Initial Message")}
                name={["initial_message"]}
                rules={[{ required: false }]}
                initialValue={data?.initial_message || t("assistants.defaultValues.initialMessage", "Hello! How can I help you today?")}
            >
                <MarkdownEditor preview="edit" />
            </Form.Item>
            <Form.Item label={t("assistants.fields.icon", "Icon")}>
                <Form.Item
                    name="icons"
                    valuePropName="file"
                    getValueFromEvent={getValueFromEvent}
                    noStyle
                >
                    <Upload.Dragger
                        listType="picture"
                        maxCount={1}
                        defaultFileList={
                            data?.icon && !iconToDelete
                                ? [
                                      {
                                          uid: "-1",
                                          name: data?.icon_label || "",
                                          status: "done",
                                          url: data?.icon || "",
                                      },
                                  ]
                                : []
                        }
                        onRemove={() => setIconToDelete(true)}
                        beforeUpload={() => false}
                    >
                        <p className="ant-upload-text">
                            {t(
                                "assistants.fields.dragDropText",
                                "Drag & drop a file in this area"
                            )}
                        </p>
                    </Upload.Dragger>
                </Form.Item>
            </Form.Item>
                </Collapse.Panel>
            </Collapse>
        </Form>
    );
};
