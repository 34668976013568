import { Edit, useForm } from "@refinedev/antd";
import { AssistantType } from "./types";

import { AssistantForm } from "./form";
import { InitialSuggestionsTable } from "./initial-suggestion-table";
// import { DocumentTable } from "./document-table";

export const AssistantEdit = () => {
    const {formProps, queryResult, saveButtonProps} = useForm();

    return (
        <Edit saveButtonProps={saveButtonProps}>
            <AssistantForm data={queryResult?.data?.data as AssistantType} formProps={formProps}/>
            <InitialSuggestionsTable assistantId={parseInt(queryResult?.data?.data?.id)} isEditable />
            {/* <DocumentTable assistantId={parseInt(queryResult?.data?.data?.id)} isEditable /> */}
        </Edit>
    );
};
