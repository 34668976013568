import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { ContentDocumentType } from "../../pages/content-documents/types";

const Thumbnail = ({
    contentDocument,
}: {
    contentDocument: ContentDocumentType;
}) => {
    return (
        <Link to={`/content-documents/show/${contentDocument.id}`}>
            <Card>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div
                        style={{
                            position: "relative",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                            overflow: "hidden",
                            width: 200,
                            height: 300,
                        }}
                    >
                        {contentDocument.thumbnail && (
                            <img
                                src={contentDocument.thumbnail}
                                alt={contentDocument.title}
                                style={{
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                }}
                            />
                        )}
                    </div>
                </div>

                <h3 className="mt-4">{contentDocument.title}</h3>
            </Card>
        </Link>
    );
};

export const ThumbnailList = ({
    contentDocuments,
}: {
    contentDocuments: ContentDocumentType[];
}) => {
    return (
        <Row gutter={[16, 16]}>
            {contentDocuments.map((contentDocument: ContentDocumentType) => (
                <Col xs={24} sm={12} md={8} xl={6} key={contentDocument.id}>
                    <Thumbnail contentDocument={contentDocument} />
                </Col>
            ))}
        </Row>
    );
};
