import { useState } from "react";

import { useOne, useUpdate } from "@refinedev/core";

import {
    BankOutlined,
    CloseOutlined,
    EditOutlined,
    MailOutlined,
    UserSwitchOutlined,
} from "@ant-design/icons";
import {
    Button,
    Card,
    Drawer,
    Input,
    Select,
    Space,
    Spin,
    Typography,
} from "antd";
import { usePermissions } from "@refinedev/core";

import { CustomAvatar } from "../../custom-avatar";
import { SingleElementForm } from "../../single-element-form";
import { Text } from "../../text";
import styles from "./index.module.css";
import { useSelect } from "@refinedev/antd";

type Props = {
    opened: boolean;
    setOpened: (opened: boolean) => void;
    userId: number;
};

type FormKeys = "email" | "user_group" | "regular_user_mode";

interface ICompany {
    id: number;
    name: string;
}

export const AccountSettings = ({ opened, setOpened, userId }: Props) => {
    const [activeForm, setActiveForm] = useState<FormKeys>();

    const { data, isLoading, isError } = useOne({
        resource: "users",
        id: userId,
        queryOptions: {
            enabled: opened,
        },
        meta: {
            chatbotId: null,
            subchatbotId: null,
        }
    });

    const { data: permissionsData } = usePermissions({});

    const { selectProps: userGroupSelectProps } = useSelect<ICompany>({
        resource: "user-groups",
        searchField: "name",
        optionLabel: "name",
        debounce: 500,
        meta: {
            chatbotId: null,
            subchatbotId: null,
        }
    });

    const { mutate: updateMutation } = useUpdate();

    const closeModal = () => {
        setOpened(false);
    };

    if (isError) {
        closeModal();
        return null;
    }

    if (isLoading) {
        return (
            <Drawer
                open={opened}
                width={756}
                styles={{
                    body: {
                        background: "#f5f5f5",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <Spin />
            </Drawer>
        );
    }

    const {
        id,
        name,
        email,
        profile_picture: avatarUrl,
        userGroup,
        regular_user_mode,
    } = data?.data ?? {};

    const getActiveForm = (key: FormKeys) => {
        if (activeForm === key) {
            return "form";
        }

        if (!data?.data[key]) {
            return "empty";
        }

        return "view";
    };

    return (
        <Drawer
            onClose={closeModal}
            open={opened}
            width={756}
            styles={{
                body: { background: "#f5f5f5", padding: 0 },
                header: { display: "none" },
            }}
        >
            <div className={styles.header}>
                <Text strong>Account Settings</Text>
                <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => closeModal()}
                />
            </div>
            <div className={styles.container}>
                <div className={styles.name}>
                    <CustomAvatar
                        style={{
                            marginRight: "1rem",
                            flexShrink: 0,
                            fontSize: "40px",
                        }}
                        size={96}
                        src={avatarUrl}
                        name={name}
                    />
                    <Typography.Title
                        level={3}
                        style={{ padding: 0, margin: 0, width: "100%" }}
                        className={styles.title}
                        editable={{
                            onChange(value: string) {
                                updateMutation({
                                    resource: "users",
                                    id: id || -1,
                                    values: { name: value },
                                    mutationMode: "optimistic",
                                    successNotification: false,
                                });
                            },
                            triggerType: ["text", "icon"],
                            icon: (
                                <EditOutlined
                                    className={styles.titleEditIcon}
                                />
                            ),
                        }}
                    >
                        {name}
                    </Typography.Title>
                </div>
                {permissionsData?.includes("admin") ||
                permissionsData?.includes("hidden_admin") ? (
                    <Card
                        title={
                            <Space size={15}>
                                <Text size="sm">Admin Information</Text>
                            </Space>
                        }
                        styles={{
                            header: { padding: "0 12px" },
                            body: { padding: "0" },
                        }}
                    >
                        <SingleElementForm
                            useFormProps={{
                                id,
                                resource: "users",
                            }}
                            formProps={{ initialValues: { userGroup } }}
                            style={{ borderBottom: "none" }}
                            icon={<BankOutlined className="tertiary" />}
                            state={getActiveForm("user_group")}
                            itemProps={{
                                name: "user_group",
                                label: "Group",
                            }}
                            view={
                                <Text>
                                    {userGroupSelectProps.options?.find(
                                        (item) => item.value === userGroup
                                    )?.label || "Unknown"}
                                </Text>
                            }
                            onClick={() => setActiveForm("user_group")}
                            onUpdate={() => setActiveForm(undefined)}
                            onCancel={() => setActiveForm(undefined)}
                        >
                            <Select
                                rootClassName="w-full"
                                {...userGroupSelectProps}
                            />
                        </SingleElementForm>
                        <SingleElementForm
                            useFormProps={{
                                id,
                                resource: "users",
                            }}
                            formProps={{ initialValues: { regular_user_mode } }}
                            style={{ borderBottom: "none" }}
                            icon={<UserSwitchOutlined className="tertiary" />}
                            state={getActiveForm("regular_user_mode")}
                            itemProps={{
                                name: "regular_user_mode",
                                label: "Regular User Mode",
                            }}
                            view={
                                regular_user_mode ? (
                                    <Text>Enabled</Text>
                                ) : (
                                    <Text>Disabled</Text>
                                )
                            }
                            onClick={() => setActiveForm("regular_user_mode")}
                            onUpdate={() => setActiveForm(undefined)}
                            onCancel={() => setActiveForm(undefined)}
                        >
                            <Select
                                defaultValue={
                                    regular_user_mode ? "Enabled" : "Disabled"
                                }
                            >
                                <Select.Option value={true}>
                                    Enabled
                                </Select.Option>
                                <Select.Option value={false}>
                                    Disabled
                                </Select.Option>
                            </Select>
                        </SingleElementForm>
                    </Card>
                ) : null}
                <Card
                    title={
                        <Space size={15}>
                            <Text size="sm">Profile Information</Text>
                        </Space>
                    }
                    styles={{
                        header: { padding: "0 12px" },
                        body: { padding: "0" },
                    }}
                >
                    <SingleElementForm
                        useFormProps={{
                            id,
                            resource: "users",
                        }}
                        formProps={{ initialValues: { email } }}
                        icon={<MailOutlined className="tertiary" />}
                        state={getActiveForm("email")}
                        itemProps={{
                            name: "email",
                            label: "Email",
                        }}
                        view={<Text>{email}</Text>}
                        onClick={() => setActiveForm("email")}
                        onUpdate={() => setActiveForm(undefined)}
                        onCancel={() => setActiveForm(undefined)}
                    >
                        <Input />
                    </SingleElementForm>
                </Card>
            </div>
        </Drawer>
    );
};
