import { DeleteButton, EditButton, List, ShowButton, useTable } from "@refinedev/antd";
import { Table, Space } from "antd";
import { BaseRecord, useGetIdentity, CrudFilters } from "@refinedev/core";
import { IIdentity } from "../../authProvider";
import { ContentPageType } from "./types";
import { t } from "i18next";

export const ContentPageList = () => {
    const { tableProps, tableQueryResult } = useTable<ContentPageType>({
        syncWithLocation: true,
        resource: "content-pages",
        filters: {
            permanent: [
                {
                    field: "parent",
                    operator: "eq",
                    value: "null",
                },
            ] as CrudFilters,
        },
    });
    const { data: identity } = useGetIdentity<IIdentity>();

    if (tableQueryResult.isLoading || !identity) {
        return <div>Loading...</div>;
    }

    const data = tableQueryResult.data?.data || [];

    const columns = [
        { title: "Title", dataIndex: "title", key: "title" },
        {
            title: "Draft",
            dataIndex: "is_draft",
            key: "is_draft",
            render: (value: boolean) => (value ? "Yes" : "No"),
        },
        {
            title: "Last Updated",
            dataIndex: "updated_at",
            key: "updated_at",
            render: (value: string) => new Date(value).toLocaleString()
        },
        {
            title: "Children",
            key: "children",
            render: (record: ContentPageType) => (record.children?.length ? record.children.length : "None"),
        },
        {
            title: "Assistant",
            dataIndex: "assistant_label",
            key: "assistant_label",
            render: (value: string) => value,
        },
        {
            title: t("tableHeaders.actions", "Actions"),
            key: "actions",
            render: (_: React.ReactNode, record: BaseRecord) => (
                <Space>
                    <EditButton hideText size="small" recordItemId={record.id} />
                    <ShowButton hideText size="small" recordItemId={record.id} />
                    <DeleteButton hideText size="small" recordItemId={record.id} />
                </Space>
            ),
        },
    ];

    return (
        <List canCreate={identity?.is_superuser}>
            <Table
                {...tableProps}
                dataSource={data}
                columns={columns}
                rowKey="id"
            />
        </List>
    );
};
