import { CopyFilled, MessageOutlined } from "@ant-design/icons";
import {
    DateField,
    EditButton,
    MarkdownField,
    NumberField,
    Show,
    TextField,
} from "@refinedev/antd";
import { useShow } from "@refinedev/core";
import { Button, Card, Tag, Typography, notification } from "antd";
import { Assistant, ContentType } from "components/assistant";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { InitialSuggestionsTable } from "./initial-suggestion-table";
import { DocumentTable } from "./document-table";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

export const AssistantShow = () => {
    const { queryResult } = useShow({});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { id: assistantId } = useParams<{
        id: string;
    }>();

    const [isAssistantVisible, setAssistantVisible] = useState(true);

    const copyPublicAssistantLink = () => {
        const publicLink = `${window.location.origin}/assistant-public/show/${data?.data.uuid}`;
        navigator.clipboard.writeText(publicLink);
        notification.success({
            message: "Public link copied to clipboard",
            description: publicLink,
            duration: 2,
        });
    };
    const { t } = useTranslation();

    if (!assistantId) {
        return null;
    }

    return (
        <div style={{ height: "80vh" }}>
            <Show
                breadcrumb
                title={record?.name}
                isLoading={isLoading}
                contentProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0)",
                        margin: "-24px",
                        marginTop: "-16px",
                    },
                }}
                headerButtons={({ editButtonProps }) => {
                    return (
                        <>
                            <Button
                                size="middle"
                                onClick={copyPublicAssistantLink}
                                icon={<CopyFilled />}
                            >
                                {t("actions.copyPublicLink", "Copy Public Link")}
                            </Button>
                            <EditButton {...editButtonProps} />
                            <Button
                                type="primary"
                                onClick={() =>
                                    setAssistantVisible(!isAssistantVisible)
                                }
                                icon={<MessageOutlined />}
                            >
                                {t("assistant.toggleAssistant", "Toggle Assistant")}
                            </Button>
                        </>
                    );
                }}
            >
                <PanelGroup direction="horizontal">
                    <Panel id="P-quiz" minSize={40}>
                        <Card style={{ height: "70vh" }}>
                            <Title level={5}>{"ID"}</Title>
                            <NumberField value={record?.id ?? ""} />
                            <Title level={5}>{"Name"}</Title>
                            <div className="flex flex-row">
                                <TextField value={record?.name} />
                                <Tag color="blue" className="ml-4">
                                    {"Draft"}
                                </Tag>
                            </div>
                            <Title level={5}>{"Language"}</Title>
                            <MarkdownField value={record?.language} />
                            <Title level={5}>{"CreatedAt"}</Title>
                            <DateField value={record?.createdAt} />
                            <InitialSuggestionsTable
                                assistantId={parseInt(assistantId)}
                            />
                            <DocumentTable
                                assistantId={parseInt(assistantId)}
                            />
                        </Card>
                    </Panel>
                    {isAssistantVisible && (
                        <>
                            <PanelResizeHandle />
                            <Panel id="P-assistant" minSize={30}>
                                <Assistant
                                    assistantId={
                                        assistantId as unknown as number
                                    }
                                    contentType={ContentType.ASSISTANT}
                                    contentId={assistantId as unknown as number}
                                />
                            </Panel>
                        </>
                    )}
                </PanelGroup>
            </Show>
        </div>
    );
};
