import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

import {
    GetOneParams,
    GetListParams,
    CreateParams,
    UpdateParams,
    DeleteOneParams,
    DeleteManyParams,
    CrudOperators,
    CrudFilters,
    MetaQuery,
} from "@refinedev/core";

import { TOKEN_KEY, REFRESH_KEY } from "../authProvider";
import { getCSRFToken } from '../auth/django'

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(TOKEN_KEY);
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers["X-CSRFToken"] = getCSRFToken();
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            try {
                // Attempt to refresh the token

                const response = await axios.post('/api/token/refresh/', {
                    refresh: localStorage.getItem(REFRESH_KEY)
                }, {
                    headers: {
                        'X-CSRFToken': getCSRFToken()
                    }
                });
                const newToken = response.data.access;

                // Store the new token
                localStorage.setItem(TOKEN_KEY, newToken);

                // Update the original request with the new token
                error.config.headers['Authorization'] = `Bearer ${newToken}`;

                // Retry the original request
                return axiosInstance(error.config);
            } catch (refreshError) {
                // Handle token refresh failure
                console.log("Token refresh failed:", refreshError);
                return Promise.reject(refreshError);
            }
        }
        return Promise.reject(error);
    }
);

const createUrlPrefix = (
    apiUrl: string,
    resource: string,
    meta?: MetaQuery
) => {
    const { assistantId, userGroupId } = meta ?? {};
    if (assistantId) {
        return `${apiUrl}/assistants/${assistantId}/${resource}/`;
    }
    if (userGroupId) {
        return `${apiUrl}/user-groups/${userGroupId}/${resource}/`;
    }
    return `${apiUrl}/${resource}/`;
};

export const dataProvider = (
    apiUrl: string,
    httpClient: AxiosInstance = axiosInstance
) => ({
    getOne: async ({ resource, id, meta }: GetOneParams) => {
        const urlPrefix = createUrlPrefix(apiUrl, resource, meta);
        const url = `${urlPrefix}${id}/`;
        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const { data } = await httpClient[requestMethod](url, { headers });
        return { data };
    },

    getList: async ({ resource, pagination, filters, meta }: GetListParams) => {
        const url = createUrlPrefix(apiUrl, resource, meta);
        const { headers: headersFromMeta, method } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "get";

        const query: Record<string, unknown> = {};

        if (pagination) {
            const { current = 1, pageSize = 10 } = pagination;
            query.offset = (current - 1) * pageSize;
            query.limit = pageSize;
        }

        if (filters) {
            const queryFilters = generateFilter(filters);
            Object.assign(query, queryFilters);
        }

        const { data } = await httpClient[requestMethod](
            `${url}?${stringify(query)}`,
            { headers: headersFromMeta }
        );
        return {
            data: data.results || data,
            total: data.count || data.length,
        };
    },

    create: async ({ resource, variables, meta }: CreateParams) => {
        const url = createUrlPrefix(apiUrl, resource, meta);
        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "post";

        const formData = new FormData();
        let isFilePresent = false;
        Object.keys(variables as Record<string, any>).forEach((key) => {
            if ((variables as Record<string, any>)[key] instanceof File) {
                isFilePresent = true;
            }
            formData.append(key, (variables as Record<string, any>)[key]);
        });
        if (isFilePresent) {
            const { data } = await httpClient[requestMethod](url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...headers,
                },
            });
            return { data };
        }

        const { data } = await httpClient[requestMethod](url, variables, {
            headers,
        });
        return { data };
    },

    update: async ({
        resource,
        id,
        variables,
        meta,
    }: UpdateParams & { variables: Record<string, any> }) => {
        const urlPrefix = createUrlPrefix(apiUrl, resource, meta);
        const url = `${urlPrefix}${id}/`;

        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "patch";

        const formData = new FormData();
        let isFilePresent = false;
        Object.keys(variables as Record<string, any>).forEach((key) => {
            if ((variables as Record<string, any>)[key] instanceof File) {
                isFilePresent = true;
            }
            formData.append(key, (variables as Record<string, any>)[key]);
        });
        if (isFilePresent) {
            const { data } = await httpClient[requestMethod](url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    ...headers,
                },
            });
            return { data };
        }

        const { data } = await httpClient[requestMethod](url, variables, {
            headers,
        });
        return { data };
    },

    deleteOne: async ({ resource, id, meta }: DeleteOneParams) => {
        const urlPrefix = createUrlPrefix(apiUrl, resource, meta);
        const url = `${urlPrefix}${id}/`;
        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "delete";

        await httpClient[requestMethod](url, { headers });
        return { data: {} };
    },

    deleteMany: async ({ resource, ids, meta }: DeleteManyParams) => {
        const url = createUrlPrefix(apiUrl, resource, meta);
        const { headers, method } = meta ?? {};
        const requestMethod = (method as MethodTypes) ?? "delete";

        await httpClient[requestMethod](`${url}?${stringify({ id: ids })}`, {
            headers,
        });
        return { data: {} };
    },

    custom: async ({ url, method, data, meta }: any) => {
        const { headers } = meta ?? {};
        const requestMethod = (method as MethodTypesWithBody) ?? "get";

        const { data: responseData } = await httpClient[requestMethod](
            url,
            data,
            {
                headers,
            }
        );
        return { data: responseData };
    },

    getApiUrl: () => apiUrl,
});

// Helper Functions

const mapOperator = (operator: CrudOperators): string => {
    switch (operator) {
        case "contains":
            return "icontains";
        default:
            return operator;
    }
};

const generateFilter = (filters: CrudFilters) => {
    const queryFilters: { [key: string]: string } = {};

    if (filters) {
        filters.map((filter) => {
            if (filter.operator === "or" || filter.operator === "and") {
                throw new Error(
                    `[@refinedev/simple-rest]: Operators 'or' and 'and' are not supported. You should implement this in the backend. See more information on: /docs/data/data-provider#creating-a-data-provider`
                );
            }

            if ("field" in filter) {
                const { field, operator, value } = filter;

                if (field === "q") {
                    queryFilters[field] = value;
                    return;
                }

                const mappedOperator = mapOperator(operator);
                queryFilters[`${field}__${mappedOperator}`] = value;
            }
        });
    }

    return queryFilters;
};
