import React from 'react';
import { useList } from "@refinedev/core";
import { Card, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { ContentPageType } from "./types";

export const ContentPageHome: React.FC = () => {
  const { data, isLoading } = useList<ContentPageType>({
    resource: "content-pages",
    filters: [
      {
        field: "parent",
        operator: "eq",
        value: "null",
      },
    ],
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const topLevelPages = data?.data.filter((page: ContentPageType) => !page.parent) || [];

  return (
    <div className="p-4">
      <Row gutter={[16, 16]}>
        {topLevelPages.map((page: ContentPageType) => (
          <Col key={page.id} xs={24} sm={12} md={8} lg={8} xl={8}>
            <Link to={`/content-pages/show/${page.id}`}>
              <Card
                hoverable
                cover={
                  <img
                    alt={page.title}
                    src={page.thumbnail || `https://picsum.photos/seed/${page.id}/300/200`}
                    className="h-40 object-cover"
                  />
                }
                className="h-full"
              >
                <Card.Meta
                  title={page.title}
                  description={page.is_draft ? "Draft" : "Published"}
                />
              </Card>
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  );
};
