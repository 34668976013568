import {
    Authenticated,
    Refine,
    DataProvider,
    usePermissions,
    useGetIdentity,
} from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import { ErrorComponent, useNotificationProvider } from "@refinedev/antd";
import { App as AntdApp } from "antd";
import {
    ApiOutlined,
    BookOutlined,
    DashboardOutlined,
    HddOutlined,
    PaperClipOutlined,
    TeamOutlined,
    UserOutlined,
    VideoCameraOutlined,
    FileTextOutlined,
} from "@ant-design/icons";
import "@refinedev/antd/dist/reset.css";
import routerBindings, {
    CatchAllNavigate,
    DocumentTitleHandler,
    UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import {
    BrowserRouter,
    Navigate,
    Outlet,
    Route,
    Routes,
} from "react-router-dom";

import { ThemedLayoutV2 } from "components/layout";
import { Header } from "components/layout/header";
import { ThemedSiderV2 } from "components/layout/sider";
import { ThemedTitleV2 } from "components/layout/title";
import { dataProvider } from "api";

import { authProvider, IIdentity, useTokenRefresh } from "./authProvider";
import { ColorModeContextProvider } from "contexts/color-mode";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { Register } from "pages/register";
import "./tailwind.css";
import "./lang/i18n";
import { useTranslation } from "react-i18next";
import { Feature } from "./utilities/availableFeatures";
import { DashboardPage } from "./pages/dashboard";
import {
    UserGroupCreate,
    UserGroupEdit,
    UserGroupList,
    UserGroupShow,
} from "./pages/user-groups";

import { UserCreate, UserEdit, UserList, UserShow } from "./pages/users";
import { QuizCreate, QuizEdit, QuizList, QuizShow } from "pages/quizzes";
import {
    AssistantCreate,
    AssistantEdit,
    AssistantList,
    AssistantShow,
} from "pages/assistants";
import {
    ContentDocumentShow,
    ContentDocumentList,
    ContentDocumentEdit,
    ContentDocumentCreate,
} from "pages/content-documents";
import {
    ContentVideoCreate,
    ContentVideoEdit,
    ContentVideoList,
    ContentVideoShow,
} from "pages/content-videos";
import { ContentDocumentShowPublic } from "pages/content-documents/show-public";
import { ContentVideoShowPublic } from "pages/content-videos/show-public";
import { AssistantShowPublic } from "pages/assistants/show-public";
import { QuizShowPublic } from "pages/quizzes/show-public";
import { VerifyEmailPage, UpdatePasswordPage } from "components/pages/auth/components";
import { useCurrentTenant } from "./tenants";
import { useEffect, useMemo, useState } from "react";
import moment from "moment/min/moment-with-locales";

import { i18nProvider } from "./lang/i18n";
import { ChatMessageHistoryShow } from "pages/dashboard/chat-message-history";
import { useCurrentUserGroup } from "utilities/getCurrentUserGroup";
import { CaseCreate, CaseEdit, CaseList, CaseShow } from "pages/cases";
import { CaseShowPublic } from "pages/cases/show-public";
import { CaseAttemptsList } from "pages/cases/attempts-list";
import { CaseAttemptShow } from "pages/cases/show-attempt";
import {
    ContentPageCreate,
    ContentPageEdit,
    ContentPageList,
    ContentPageShow,
} from "pages/content-pages";
import { ContentPageHome } from "./pages/content-pages/home";

interface FeatureFilterProps {
    setFilteredFeatures: React.Dispatch<React.SetStateAction<Feature[]>>;
    setHomeRedirect: React.Dispatch<React.SetStateAction<string>>;
}
const PermissionBasedHomeRedirect: React.FC<{ homeRedirect: string }> = ({
    homeRedirect,
}) => {
    return <Navigate to={homeRedirect} replace />
};

const FeatureFilter: React.FC<FeatureFilterProps> = ({
    setFilteredFeatures,
    setHomeRedirect,
}) => {
    const { data: identity } = useGetIdentity<IIdentity>();
    const { features: tenantFeatures } = useCurrentTenant();
    const { features: userGroupFeatures } = useCurrentUserGroup();
    const { data: permissionsData } = usePermissions({});

    const filteredFeatures = useMemo(() => {
        if (identity?.is_superuser && !identity?.regular_user_mode) {
            return tenantFeatures;
        }
        return tenantFeatures.filter((feature) =>
            userGroupFeatures?.includes(feature)
        );
    }, [tenantFeatures, userGroupFeatures]);

    useEffect(() => {
        if (permissionsData?.includes("admin")) {
            setHomeRedirect("/dashboard");
        } else if (tenantFeatures.includes(Feature.ContentPages)) {
            setHomeRedirect("/content-pages-home");
        } else {
            setHomeRedirect("/content-documents/list/");
        }
    }, [permissionsData, tenantFeatures, setHomeRedirect]);

    useEffect(() => {
        setFilteredFeatures((prevFeatures) => {
            const areFeaturesEqual =
                prevFeatures.length === filteredFeatures.length &&
                prevFeatures.every(
                    (feature, index) => feature === filteredFeatures[index]
                );

            if (!areFeaturesEqual) {
                return filteredFeatures;
            }
            return prevFeatures;
        });
    }, [filteredFeatures, setFilteredFeatures]);

    return null;
};

type Route = {
    path: string;
    feature: string;
    element: JSX.Element;
    children?: Route[];
    // Add other properties as needed
};

const filterRoutesByFeature = (
    routes: Route[],
    features: string | string[]
): Route[] => {
    return routes
        .map((route) => {
            if (route.feature && !features.includes(route.feature)) {
                return null; // Exclude this route if the feature is not enabled
            }
            return route;
        })
        .filter((route): route is Route => route !== null); // Remove null entries
};

function App() {
    const apiUrl = window.location.origin + "/api";
    useTokenRefresh();
    const { t, i18n } = useTranslation();

    const { defaultLanguage } = useCurrentTenant();

    const [features, setFeatures] = useState<Feature[]>([]);
    const [homeRedirect, setHomeRedirect] = useState<string>("/");

    useEffect(() => {
        i18n.changeLanguage(defaultLanguage);
        // change the language of the moment library
        moment.locale("sl");
    }, [defaultLanguage]); // This will only re-run if defaultLanguage changes

    const routes = useMemo(
        () => [
            {
                path: "/dashboard",
                element: <DashboardPage />,
                feature: Feature.Dashboard,
                children: [
                    {
                        path: "chat-message-history/:id",
                        element: <ChatMessageHistoryShow />,
                    },
                ],
            },
            {
                path: "/assistants",
                feature: Feature.Assistants,
                children: [
                    {
                        path: "",
                        element: <AssistantList />,
                    },
                    {
                        path: "create",
                        element: <AssistantCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <AssistantEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <AssistantShow />,
                    },
                ],
            },
            {
                path: "/users",
                feature: Feature.Users,

                children: [
                    { path: "", element: <UserList /> },
                    {
                        path: "create",
                        element: <UserCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <UserEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <UserShow />,
                    },
                ],
            },
            {
                path: "/user-groups",
                feature: Feature.UserGroups,
                children: [
                    {
                        path: "",
                        element: <UserGroupList />,
                    },
                    {
                        path: "create",
                        element: <UserGroupCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <UserGroupEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <UserGroupShow />,
                    },
                ],
            },
            {
                path: "/content-documents",
                feature: Feature.Documents,
                children: [
                    {
                        path: "list",
                        element: <ContentDocumentList />,
                    },
                    {
                        path: "show/:id",
                        element: <ContentDocumentShow />,
                    },
                    {
                        path: "create",
                        element: <ContentDocumentCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <ContentDocumentEdit />,
                    },
                ],
            },
            {
                path: "/content-videos",
                feature: Feature.Videos,
                children: [
                    {
                        path: "list",
                        element: <ContentVideoList />,
                    },
                    {
                        path: "show/:id",
                        element: <ContentVideoShow />,
                    },
                    {
                        path: "create",
                        element: <ContentVideoCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <ContentVideoEdit />,
                    },
                ],
            },
            {
                path: "/quizzes",
                feature: Feature.Quizzes,
                children: [
                    {
                        path: "",
                        element: <QuizList />,
                    },
                    {
                        path: "create",
                        element: <QuizCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <QuizEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <QuizShow />,
                    },
                ],
            },
            {
                path: "/cases",
                feature: Feature.Cases,
                children: [
                    {
                        path: "",
                        element: <CaseList />,
                    },
                    {
                        path: "create",
                        element: <CaseCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <CaseEdit />,
                    },
                    {
                        path: "attempts/:id",
                        element: <CaseAttemptsList />,
                    },
                    {
                        path: ":case_id/attempt/:attempt_id",
                        element: <CaseAttemptShow />,
                    },
                    {
                        path: "show/:id",
                        element: <CaseShow />,
                    },
                ],
            },
            {
                path: "/content-pages",
                feature: Feature.ContentPages,
                children: [
                    {
                        path: "",
                        element: <ContentPageList />,
                    },
                    {
                        path: "create",
                        element: <ContentPageCreate />,
                    },
                    {
                        path: "edit/:id",
                        element: <ContentPageEdit />,
                    },
                    {
                        path: "show/:id",
                        element: <ContentPageShow />,
                    },
                ],
            },
            {
                path: "/content-pages-home",
                element: <ContentPageHome />,
                feature: Feature.ContentPages,
            },
            {
                path: "*",
                element: <ErrorComponent />,
            },
        ],
        []
    );

    const filteredRoutes = useMemo(
        () => filterRoutesByFeature(routes as Route[], features),
        [routes, features]
    );

    const { title } = useCurrentTenant();

    // Replace AceFlow with the tenant title
    useEffect(() => {
        document.title = document.title.replace(/AceFlow/g, title);
    }, [title]);

    return (
        <BrowserRouter>
            <ColorModeContextProvider>
                <RefineKbarProvider>
                    <AntdApp>
                        <DevtoolsProvider>
                            <Refine
                                // @ts-expect-error - something with antd's i18n provider
                                i18nProvider={i18nProvider}
                                dataProvider={
                                    dataProvider(apiUrl) as DataProvider
                                }
                                notificationProvider={useNotificationProvider}
                                routerProvider={routerBindings}
                                authProvider={authProvider}
                                resources={[
                                    {
                                        name: "dashboard",
                                        list: "/dashboard",
                                        meta: {
                                            adminOnly: true,
                                            icon: <DashboardOutlined />,
                                            label: t(
                                                "resources.dashboard",
                                                "Dashboard"
                                            ),
                                            hide: !features?.includes(
                                                Feature.Dashboard
                                            ),
                                        },
                                    },
                                    {
                                        name: "user-groups",
                                        list: "/user-groups",
                                        create: "/user-groups/create",
                                        edit: "/user-groups/edit/:id",
                                        show: "/user-groups/show/:id",
                                        meta: {
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <TeamOutlined />,
                                            label: t(
                                                "resources.groups",
                                                "Groups"
                                            ),
                                            hide: !features?.includes(
                                                Feature.UserGroups
                                            ),
                                        },
                                    },
                                    {
                                        name: "users",
                                        list: "/users",
                                        create: "/users/create",
                                        edit: "/users/edit/:id",
                                        show: "/users/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.users",
                                                "Users"
                                            ),
                                            canDelete: true,
                                            adminOnly: true,
                                            icon: <UserOutlined />,
                                            hide: !features?.includes(
                                                Feature.Users
                                            ),
                                        },
                                    },
                                    {
                                        name: "assistants",
                                        list: "/assistants",
                                        create: "/assistants/create",
                                        edit: "/assistants/edit/:id",
                                        show: "/assistants/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.assistants",
                                                "Assistants"
                                            ),
                                            canDelete: true,
                                            icon: <ApiOutlined />,
                                            adminOnly: true,
                                            hide: !features?.includes(
                                                Feature.Assistants
                                            ),
                                        },
                                    },
                                    {
                                        name: "content-documents",
                                        list: "/content-documents/list",
                                        show: "/content-documents/show/:id",
                                        create: "/content-documents/create",
                                        edit: "/content-documents/edit/:id",
                                        meta: {
                                            label: t(
                                                "resources.documents",
                                                "Documents"
                                            ),
                                            canDelete: true,
                                            icon: <BookOutlined />,
                                            hide: !features?.includes(
                                                Feature.Documents
                                            ),
                                        },
                                    },
                                    {
                                        name: "content-videos",
                                        list: "/content-videos/list",
                                        show: "/content-videos/show/:id",
                                        create: "/content-videos/create",
                                        edit: "/content-videos/edit/:id",
                                        meta: {
                                            label: t(
                                                "resources.vides",
                                                "Videos"
                                            ),
                                            canDelete: true,
                                            icon: <VideoCameraOutlined />,
                                            hide: !features?.includes(
                                                Feature.Videos
                                            ),
                                        },
                                    },
                                    {
                                        name: "quizzes",
                                        list: "/quizzes",
                                        create: "/quizzes/create",
                                        edit: "/quizzes/edit/:id",
                                        show: "/quizzes/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.quizzes",
                                                "Quizzes"
                                            ),
                                            canDelete: true,
                                            icon: <HddOutlined />,
                                            hide: !features?.includes(
                                                Feature.Quizzes
                                            ),
                                        },
                                    },
                                    {
                                        name: "cases",
                                        list: "/cases",
                                        create: "/cases/create",
                                        edit: "/cases/edit/:id",
                                        show: "/cases/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.cases",
                                                "Cases"
                                            ),
                                            canDelete: true,
                                            icon: <PaperClipOutlined />,
                                            hide: !features?.includes(
                                                Feature.Cases
                                            ),
                                        },
                                    },
                                    {
                                        name: "multiple-choice-questions",
                                        list: "/multiple-choice-questions",
                                        create: "/multiple-choice-questions/create",
                                        edit: "/multiple-choice-questions/edit/:id",
                                        show: "/multiple-choice-questions/show/:id",
                                        meta: {
                                            hide: true,
                                            canDelete: true,
                                        },
                                    },
                                    {
                                        name: "multiple-choice-answers",
                                        list: "/multiple-choice-answers",
                                        create: "/multiple-choice-answers/create",
                                        edit: "/multiple-choice-answers/edit/:id",
                                        show: "/multiple-choice-answers/show/:id",
                                        meta: {
                                            hide: true,
                                            canDelete: true,
                                        },
                                    },
                                    {
                                        name: "content-pages-home",
                                        list: "/content-pages-home",
                                        meta: {
                                            label: t(
                                                "resources.contentPagesHome",
                                                "Content Home"
                                            ),
                                            icon: <FileTextOutlined />,
                                            hide: !features?.includes(
                                                Feature.ContentPagesAdmin
                                            ),
                                            adminOnly: true,
                                        },
                                    },
                                    {
                                        name: "content-pages",
                                        list: "/content-pages",
                                        create: "/content-pages/create",
                                        edit: "/content-pages/edit/:id",
                                        show: "/content-pages/show/:id",
                                        meta: {
                                            label: t(
                                                "resources.contentPages",
                                                "Content Pages"
                                            ),
                                            canDelete: true,
                                            icon: <FileTextOutlined />,
                                            hide: !features?.includes(
                                                Feature.ContentPagesAdmin
                                            ),
                                        },
                                    },

                                ]}
                                options={{
                                    syncWithLocation: true,
                                    warnWhenUnsavedChanges: true,
                                    useNewQueryKeys: true,
                                    projectId: "gP4tlt-ePnob0-W0sNBr",
                                }}
                            >
                                <FeatureFilter
                                    setFilteredFeatures={setFeatures}
                                    setHomeRedirect={setHomeRedirect}
                                />
                                <Routes>
                                    <Route
                                        element={
                                            <Authenticated
                                                key="authenticated-inner"
                                                fallback={
                                                    <CatchAllNavigate to="/login" />
                                                }
                                            >
                                                <ThemedLayoutV2
                                                    Header={Header}
                                                    Sider={ThemedSiderV2}
                                                    Title={ThemedTitleV2}
                                                >
                                                    <Outlet />
                                                </ThemedLayoutV2>
                                            </Authenticated>
                                        }
                                    >
                                        {filteredRoutes.map((route, index) => (
                                            <Route
                                                key={index}
                                                path={route.path}
                                                element={route.element}
                                            >
                                                {route.children?.map(
                                                    (child, idx) => (
                                                        <Route
                                                            key={idx}
                                                            path={child.path}
                                                            element={
                                                                child.element
                                                            }
                                                        />
                                                    )
                                                )}
                                            </Route>
                                        ))}
                                        <Route
                                            index
                                            element={
                                                <PermissionBasedHomeRedirect homeRedirect={homeRedirect} />
                                            }
                                        />
                                        <Route
                                            path="*"
                                            element={<ErrorComponent />}
                                        />
                                    </Route>
                                    {/* <Route
                                        element={
                                            <Authenticated
                                                key="authenticated-outer"
                                                fallback={<Outlet />}
                                            >
                                                <NavigateToResource />
                                            </Authenticated>
                                        }
                                    >
                                    </Route> */}
                                    <Route path="/login" element={<Login />} />
                                    <Route
                                        path="/register"
                                        element={<Register />}
                                    />
                                    <Route
                                        path="/account/forgot-password"
                                        element={<ForgotPassword />}
                                    />
                                    <Route
                                    path="/update-password"
                                    element={<UpdatePasswordPage />}
                                    />
                                    <Route
                                        path="/account/verify-email/:token"
                                        element={<VerifyEmailPage />}
                                    />
                                    <Route
                                        path="/document-public/show/:uuid"
                                        element={<ContentDocumentShowPublic />}
                                    />
                                    <Route
                                        path="/video-public/show/:uuid"
                                        element={<ContentVideoShowPublic />}
                                    />
                                    <Route
                                        path="/assistant-public/show/:uuid"
                                        element={<AssistantShowPublic />}
                                    />
                                    <Route
                                        path="/quiz-public/show/:uuid"
                                        element={<QuizShowPublic />}
                                    />
                                    <Route
                                        path="/case-public/show/:uuid"
                                        element={<CaseShowPublic />}
                                    />
                                </Routes>
                                <RefineKbar />
                                <UnsavedChangesNotifier />
                                <DocumentTitleHandler />
                            </Refine>
                            <DevtoolsPanel />
                        </DevtoolsProvider>
                    </AntdApp>
                </RefineKbarProvider>
            </ColorModeContextProvider>
        </BrowserRouter>
    );
}

export default App;
