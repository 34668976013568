import { HttpError, useCreate } from "@refinedev/core";
import React from "react";
import { Card, Row, Table, Typography } from "antd";
import { useTable, DateField } from "@refinedev/antd";
import { useDeleteMany } from "@refinedev/core";

import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface IDocument {
    id: number;
    uuid: string;
    created_at: string;
    file_name_pretty: string;
    file: string;
}

const CreateButton = ({ assistantId }: { assistantId: number }) => {
    const { mutate, isSuccess, isError, isLoading } = useCreate<IDocument>();

    const fileInputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (isSuccess || isError) {
            if (fileInputRef.current) {
                fileInputRef.current.value = "";
            }
        }
    }, [isSuccess, isError]);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event?.target?.files;
        if (files && files.length > 0) {
            mutate({
                resource: "documents",
                meta: {
                    assistantId,
                },
                values: {
                    assistant: assistantId,
                    file: files[0],
                },
            });
        }
    };

    const { t } = useTranslation();

    const handleClick = () => {
        fileInputRef.current?.click();
    };

    return (
        <>
            <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={handleFileUpload}
            />
            <Button
                icon={<UploadOutlined />}
                onClick={handleClick}
                type="primary"
                loading={isLoading}
            >
                {t("assistantdocuments.uploadButton", "Upload")}
            </Button>
        </>
    );
};

export const DocumentTable = ({
    isEditable = false,
    assistantId,
}: {
    isEditable?: boolean;
    assistantId: number;
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>(
        []
    );
    const {
        mutate,
        isSuccess,
        isLoading: deleteManyIsLoading,
    } = useDeleteMany<IDocument>();

    const deleteSelectedItems = () => {
        mutate({
            resource: "documents",
            meta: {
                assistantId,
            },
            ids: selectedRowKeys.map(String),
        });
    };

    React.useEffect(() => {
        if (isSuccess) {
            setSelectedRowKeys([]);
        }
    }, [isSuccess]);

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = isEditable
        ? {
              selectedRowKeys,
              onChange: onSelectChange,
              selections: [
                  Table.SELECTION_ALL,
                  Table.SELECTION_INVERT,
                  Table.SELECTION_NONE,
              ],
          }
        : undefined;
    const hasSelected = selectedRowKeys.length > 0;

    const { tableProps } = useTable<IDocument, HttpError>({
        resource: "documents",
        meta: {
            assistantId,
        },
    });

    const { t } = useTranslation();

    if (!assistantId) {
        return null;
    }

    return (
        <Card className="mt-4">
            <Row justify="space-between" className="mb-4">
                <Typography.Title level={4}>
                    {t("assistantdocuments.title", "Documents")}
                </Typography.Title>
                <div className="flex-grow" />
                {isEditable && hasSelected && (
                    <Button
                        className="mr-2"
                        danger
                        onClick={deleteSelectedItems}
                        disabled={!hasSelected}
                        loading={deleteManyIsLoading}
                    >
                        {t("assistantdocuments.deleteButton", {
                            count: selectedRowKeys.length,
                        })}
                    </Button>
                )}
                {isEditable && <CreateButton assistantId={assistantId} />}
            </Row>
            <Table {...tableProps} rowKey="id" rowSelection={rowSelection}>
                <Table.Column dataIndex="id" title={t("assistantdocuments.fields.id", "ID")} />
                <Table.Column
                    dataIndex="file_name_pretty"
                    title={t("assistantdocuments.fields.file", "File")}
                    render={(text: string, record: { file: string }) => (
                        <a
                            href={record.file}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {text}
                        </a>
                    )}
                />
                <Table.Column
                    dataIndex="created_at"
                    title={t("assistantdocuments.fields.createdAt", "Created At")}
                    render={(value) => (
                        <DateField
                            value={value}
                            format="MMMM Do YYYY, hh:mm:ss"
                        />
                    )}
                />
            </Table>
        </Card>
    );
};
