import { Edit, useForm } from "@refinedev/antd";
import { Form, Select } from "antd";
import { UserGroupForm } from "./form";
import { UserGroupData } from "./types";
import { RegistrationTokenTable } from "components/registration-tokens/table";
import AccessTable from "components/access/table";
import { useList, useCreate } from "@refinedev/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCurrentTenant } from "tenants";
import { Feature, mapTenantFeaturesToChoices } from "utilities/availableFeatures";

const AvailableFeatureSelect = ({ userGroupId }: { userGroupId: number }) => {
    const { t } = useTranslation();
    interface FeatureChoices {
        value: string;
        label: string;
    }

    interface UserGroupFeature {
        id: number;
        user_group_id: number;
        name: string;
    }


    const { mutate: mutateCreate } = useCreate();

    const { data: featureChoices, isLoading } = useList<FeatureChoices>({
        resource: "feature-choices",
    });

    const { data: currentAvailableFeatures } = useList({
        resource: `user-group-features/${userGroupId}`,
        queryOptions: {
            enabled: !!userGroupId,
        }
    });

    const handleChange = (value: string[]) => {
        mutateCreate({
            resource: "user-group-features",
            values: {
                user_group_id: userGroupId,
                features: value,
            },
        });
    };

    const {features: tenantFeatures} = useCurrentTenant();
    const mappedTenantFeatures = mapTenantFeaturesToChoices(tenantFeatures);
    const filteredFeatureChoices = featureChoices?.data?.filter(
        (feature: FeatureChoices) => mappedTenantFeatures?.includes(feature.value as Feature)
    );
    const translatedFeatureChoices = filteredFeatureChoices?.map(
        (feature: FeatureChoices) => ({
            ...feature,
            label: t(`features.${feature.value}`, feature.value),
        })
    );

    const [defaultValues, setDefaultValues] = useState(null);
    useEffect(() => {
        if (currentAvailableFeatures?.data && featureChoices?.data) {
            const defaultValues = featureChoices?.data?.filter(
                (feature: FeatureChoices) =>
                    currentAvailableFeatures.data.some(
                        (currentFeature: UserGroupFeature) => currentFeature.name === feature.value
                    )
            );
            setDefaultValues(defaultValues || []);
        }
    }, [currentAvailableFeatures, featureChoices]);


    if (isLoading || !featureChoices || !featureChoices.data || !defaultValues) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <Form>
                <Form.Item
                    label={t("userGroupForm.fields.availableFeatures", "Available Features")}
                    name={["available_features"]}
                    rules={[{ required: false }]}
                    initialValue={defaultValues}
                >
                    <Select mode="multiple" options={translatedFeatureChoices} onChange={handleChange} />
                </Form.Item>
            </Form>
        </div>
    );
};

export const UserGroupEdit = () => {
    const { formProps, saveButtonProps, queryResult, formLoading } = useForm(
        {}
    );

    return (
        <Edit saveButtonProps={saveButtonProps} isLoading={formLoading}>
            <UserGroupForm
                formProps={formProps}
                data={queryResult?.data?.data as UserGroupData | undefined}
            />
            <AvailableFeatureSelect
                userGroupId={parseInt(queryResult?.data?.data?.id)}
            />
            <RegistrationTokenTable
                userGroupId={parseInt(queryResult?.data?.data?.id)}
                isEditable
            />
            <AccessTable
                userGroupId={parseInt(queryResult?.data?.data?.id)}
                isEditable
            />
        </Edit>
    );
};
