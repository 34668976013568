import {
    DeleteButton,
    EditButton,
    List,
    ShowButton,
    useTable,
} from "@refinedev/antd";
import { BaseRecord } from "@refinedev/core";
import { Space, Table } from "antd";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";

export const AssistantList = () => {
    const { tableProps } = useTable({
        syncWithLocation: true,
    });
    const { t } = useTranslation();

    return (
        <List title={t("resources.assistants", "Assistants")}>
            <Table {...tableProps} rowKey="id">
                <Table.Column dataIndex="id" title={t("tableHeaders.id", "ID")} />
                <Table.Column dataIndex="name" title={t("tableHeaders.name", "Name")} />
                <Table.Column
                    title={t("tableHeaders.actions", "Actions")}
                    dataIndex="actions"
                    align="right"
                    render={(_, record: BaseRecord) => (
                        <Space>
                            <Tooltip title={t("actions.edit", "Edit")}>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Tooltip>
                            <Tooltip title={t("actions.show", "Show")}>
                                <ShowButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Tooltip>
                            <Tooltip title={t("actions.delete", "Delete")}>
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Tooltip>
                        </Space>
                    )}
                />
            </Table>
        </List>
    );
};
